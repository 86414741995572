/* eslint-disable no-console */
import { IDependencyInitializerDetails } from "@microsoft/applicationinsights-dependencies-js";
import { ApplicationInsights, IConfig } from "@microsoft/applicationinsights-web";
import { ApiError } from "./api/types";
import { getBaseVariables } from "./variables";

let appInsights: ApplicationInsights | undefined = undefined;

export const initAppInsights = (
  config: IConfig,
  dependencyInitializerHandler?: (details: IDependencyInitializerDetails) => boolean | void
) => {
  if (appInsights) {
    return;
  }

  const appInsightsConnString = getBaseVariables().APP_INSIGHTS_CONN_STRING;

  if (!appInsightsConnString) {
    console.error("Missing app insights connection string");
    return;
  }

  appInsights = new ApplicationInsights({
    config: {
      ...config,
      connectionString: appInsightsConnString,
    },
  });

  if (dependencyInitializerHandler) {
    appInsights.addDependencyInitializer(dependencyInitializerHandler);
  }

  try {
    appInsights.loadAppInsights();
  } catch (error) {
    console.error("Failed to load app insights", error);
  }
};

const getErrorInstance = (error: unknown): Error => {
  if (error instanceof Error) {
    return error;
  }
  if (typeof error === "string") {
    return new Error(error);
  }
  return new Error(JSON.stringify(error));
};

export const logError = (error: unknown, context: string) => {
  const apiError = error as ApiError;
  if (apiError?.isCanceledRequest) {
    console.log(context, apiError.message);
    return;
  }

  console.error(context, error);

  if (appInsights) {
    appInsights.trackException(
      {
        exception: getErrorInstance(error),
      },
      {
        context,
        browserUrl: window.location.href,
      }
    );
  }
};
